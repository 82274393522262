import {
  Box, Button, Card, CardHeader, CircularProgress,
  Grid, TextField
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import ErrorMessageComponent from 'src/components/dashboard/error/ErrorMessage';
import PaymentDialog from 'src/components/dashboard/wu/infoMtcn/DialogMtcn';
import { useGetInfoForMtcnMutation } from 'src/generated/graphql';

interface FormInputs {
  mtcn: string;
}

function WesterUnionPago() {
  const { handleSubmit, register, formState: { errors } } = useForm<FormInputs>();

  const [loading, setLoading] = useState(false);
  const [errorC, setErrorC] = useState<string | null>(null);
  const [pedidoData, setPedidoData] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [getInfoMtcn, { data: dataMtcn }] = useGetInfoForMtcnMutation()


  const onSubmit = async (data) => {
    setLoading(true);
    try {
      console.log('data', data);
       await getInfoMtcn({
         variables: {
            mtcn: data.mtcn,
            counter_id: "PYW27ARP001A",
            reference_no: "PYW27ARP001A",
            identifier: "WGHHPYW270T"
         }
      })

    } catch (error) {
      console.log('error', error);
      setErrorC("Ocurrió un error al procesar la solicitud.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataMtcn?.getInfoForMtcn) {
      setPedidoData(dataMtcn.getInfoForMtcn);
      setOpenDialog(true);
    }
  }
  , [dataMtcn]);

  console.log('dataMtcn', pedidoData);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginTop: 8,
        // minHeight: '100vh',
        backgroundColor: '#f4f6f8'
      }}
    >
      <Helmet>
        <title>Wester Union | Pago</title>
      </Helmet>

      <Card sx={{ width: '40%', padding: 4 }}>
        <CardHeader subheader='Consulta de MTCN' />
        <Box sx={{ p: 2 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} sx={{ width: "100%" }}>
                <TextField
                  label='Ingresar MTCN'
                  variant='outlined'
                  placeholder='Ingrese el código de verificación'
                  fullWidth
                  error={!!errors.mtcn}
                  helperText={errors.mtcn?.message}
                  {...register('mtcn', {
                    required: 'Ingrese el MTCN para continuar',
                    pattern: {
                      value: /^[0-9]+$/,
                      message: 'Solo se permiten números'
                    },
                    maxLength: {
                      value: 10,
                      message: 'El MTCN debe tener 10 dígitos'
                    }
                  })}
                />
              </Grid>

              <Grid item sx={{ width: "100%" }}>
                <Button
                  variant='contained'
                  type='submit'
                  size="large"
                  fullWidth
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                >
                  {loading ? 'Verificando...' : 'Verificar'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>

        {errors.mtcn && <ErrorMessageComponent message={errors.mtcn.message} />}
        {errorC && <ErrorMessageComponent message={errorC} />}
      </Card>
       <PaymentDialog open={openDialog} onClose={() => setOpenDialog(false)} pedidoData={pedidoData} />
    </Box>
  );
}

export default WesterUnionPago;
