import { useEffect } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { Toaster } from "sonner";
import GlobalStyles from "./components/GlobalStyles";
import RTL from "./components/RTL";
import SplashScreen from "./components/SplashScreen";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import routes from "./routes";
import { createTheme } from "./theme";


const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  const auth = useAuth();
  useScrollReset();
  useEffect(()=>{
    console.log('auth app',auth)
  }, [auth])

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <Toaster position="top-right" richColors/>
      <RTL direction={settings.direction}>
          <GlobalStyles />
          {/* {true ? null : <SettingsDrawer />} */}
          <link rel="stylesheet"
href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          {auth.isInitialized ?
            content :
           <SplashScreen />
          }
      </RTL>
    </ThemeProvider>

  );
};

export default App;
