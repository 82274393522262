import {
  Button, Card, CardContent,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Divider,
  Typography
} from '@material-ui/core';

// Definir la estructura de los datos esperados
interface SenderReceiver {
  given_name: string;
  paternal_name: string;
  maternal_name?: string;
}

interface Currency {
  country_code: string;
  currency_code: string;
}

interface ForeignSystem {
  identifier: string;
  reference_no: string;
  counter_id: string;
}

// Definir la estructura del pedido
interface PedidoData {
  status: string;
  sender: SenderReceiver;
  receiver: SenderReceiver;
  amount: string;
  currency: Currency;
  filing_date: string;
  filing_time: string;
  foreign_system: ForeignSystem;
}

// Definir los tipos de los props
interface PaymentDialogProps {
  open: boolean;
  onClose: () => void;
  pedidoData: PedidoData | null;
}

// Componente
const PaymentDialog: React.FC<PaymentDialogProps> = ({ open, onClose, pedidoData }) => {
  if (!pedidoData) return null; // Si no hay datos, no renderizar nada

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        Información del Pago
      </DialogTitle>
      <DialogContent>
        <Card variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
          <CardContent>
            {/* Estado */}
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#4caf50" }}>
              Estado: {pedidoData.status || "N/A"}
            </Typography>
            <Divider sx={{ my: 1 }} />

            {/* Datos del remitente */}
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Remitente</Typography>
            <Typography variant="body1">
              {pedidoData.sender.given_name} {pedidoData.sender.paternal_name} {pedidoData.sender.maternal_name || ""}
            </Typography>
            <Divider sx={{ my: 1 }} />

            {/* Datos del receptor */}
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Receptor</Typography>
            <Typography variant="body1">
              {pedidoData.receiver.given_name} {pedidoData.receiver.paternal_name} {pedidoData.receiver.maternal_name || ""}
            </Typography>
            <Divider sx={{ my: 1 }} />

            {/* Monto y moneda */}
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Monto</Typography>
            <Typography variant="body1">
              {pedidoData.amount} {pedidoData.currency.currency_code}
            </Typography>
            <Divider sx={{ my: 1 }} />

            {/* Fecha y hora */}
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>Fecha de Envío</Typography>
            <Typography variant="body1">
              {pedidoData.filing_date} - {pedidoData.filing_time}
            </Typography>
            <Divider sx={{ my: 1 }} />
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
