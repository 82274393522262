import { createContext, useContext, useEffect, useState } from 'react';


interface WesterUnionContextProps {
	children: React.ReactNode;
	value: {
		handleNext: () => void;
		handleBack: () => void;
	};
}



interface WesterUnionContextValue {
	handleNext: (path?: string) => void;
	handleBack: () => void;
  uuid: string;
  setUuid: (uuid: string) => void;
	userExists: boolean;
	setUserExists: (exists: boolean) => void;
	userDataForm: Record<string, any>;
  dataPago: Record<string, any>;
  setDataPago: (data: Record<string, any>) => void;
	setUserDataFirstForm: (userData: Record<string, any>) => void;
  userDataSecondForm: Record<string, any>;
  setUserDataSecondForm: (userData: Record<string, any>) => void;
  userDataRegimenForm: Record<string, any>;
  setUserDataRegimenForm: (userData: Record<string, any>) => void;
  cliente_id: string;
  setClienteId: (cliente_id: string) => void;
}

const defaultValue: WesterUnionContextValue = {
	handleNext: () => {},
	handleBack: () => {},
	userDataForm: {},
	userExists: false,
	setUserExists: () => {},
  dataPago: {},
  setDataPago: () => {},
	setUserDataFirstForm: () => {},
  userDataSecondForm: {},
  setUserDataSecondForm: () => {},
  userDataRegimenForm: {},
  setUserDataRegimenForm: () => {},
  uuid: '',
  setUuid: () => {},
  cliente_id: '',
  setClienteId: () => {}
};

const WesterUnionContext = createContext<WesterUnionContextValue>(defaultValue);

export const useWesterUnionContext = () => useContext(WesterUnionContext);

export const WesterUnionProvider = ({ children, value }: WesterUnionContextProps) => {
	const [ userExists, setUserExists ] = useState<boolean>(false);
  const [ userDataForm, setUserDataFirstForm ] = useState<Record<string, any>>({});
  const [ userDataSecondForm, setUserDataSecondForm ] = useState<Record<string, any>>({});
  const [ userDataRegimenForm, setUserDataRegimenForm ] = useState<Record<string, any>>({});
  const [ dataPago, setDataPago ] = useState<Record<string, any>>({});
  const [ uuid, setUuid ] = useState<string>('');
  const [ cliente_id, setClienteId ] = useState<string>('');

	const contextValue = {
		...value,
		userExists,
		setUserExists,
    userDataForm,
    setUserDataFirstForm,
    userDataSecondForm,
    setUserDataSecondForm,
    userDataRegimenForm,
    setUserDataRegimenForm,
    uuid,
    setUuid,
    dataPago,
    setDataPago,
    cliente_id,
    setClienteId
	};
  useEffect(() => {
  }, [userExists])

	return <WesterUnionContext.Provider value={contextValue}>
    {children}
    </WesterUnionContext.Provider>;
};
